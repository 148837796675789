@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  --primary-color-alt: #5fad1f;
  --primary-shadow: #a2d17c;
  --primary-link: #438a08;
  --primary-link-alt: #2f6402;
  --secondary-text: #5b5b5b;
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
  --pantone-0: #f7f9f8;
  --pantone-50: #e0e8e1;
  --pantone-100: #c3d4c7;
  --pantone-200: #a7bfac;
  --pantone-300: #8aab91;
  --pantone-400: #6e9676;
  --pantone-500: #587b5f;
  --pantone-600: #445e49;
  --pantone-700: #2f4233;
  --pantone-800: #1b251d;
  --pantone-900: #060907;
  --amber-50: #f7fee7;
  --amber-100: #ecfccb;
  --amber-200: #d9f99d;
  --amber-300: #bef264;
  --amber-400: #a3e635;
  --amber-500: #84cc16;
  --amber-600: #65a30d;
  --amber-700: #4d7c0f;
  --amber-800: #3f6212;
  --amber-900: #365314;
  --green-50: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;
}

body {
  padding: 0px 0px;
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0.1px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: var(--gray-900);
  line-height: 30px;
  /* background: #ebf0f5; */
  background-color: rgba(241, 245, 249, 0.5411764706);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #414141;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Josefin Sans", sans-serif;
  color: var(--gray-900);
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(50, 247, 32, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-alt);
}

.main {
  min-height: 100vh;
}

.image-cover {
  object-fit: cover;
}

.clear {
  font-size: 0px;
  line-height: 0px;
  clear: both;
}

.mess-login {
  position: absolute;
  margin-top: -8rem;
  margin-left: 6rem;
}

.order-text-blue {
  color: blue;
}

.order-text-orange {
  color: orange;
}

.order-text-green {
  color: green;
}

.order-text-red {
  color: red;
}

a:focus,
a:hover {
  text-decoration: none;
}

li:focus {
  outline: none;
}

a:focus {
  outline: none;
}

.btn {
  border-radius: 0;
}

.table-responsive .btn {
  padding: 5px 6px;
}

.form-group {
  margin-bottom: 15px;
}

.text-align {
  text-align: center;
}

.m-tb0 {
  margin: 0;
}

.m-b15 {
  margin-bottom: 15px !important;
}

.p-checkbox-label {
  line-height: 1;
  color: var(--gray-600);
  font-weight: 400;
}

label {
  font-weight: 500;
  display: block;
}

#topcontrol {
  background: url(../images/go-top.gif) no-repeat;
  width: 48px;
  height: 48px;
  z-index: 99999;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  /* IE 6-9 */
  transition: all 0.5s ease 0s;
  margin: 0 0 20px 0;
}

#topcontrol:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  transition: all 0.5s ease 0s;
}

.loginbox {
  padding: 15px;
  margin: 30% auto;
  max-width: 430px;
  position: relative;
}

.headsection {
  padding: 8px 0;
  margin: 0 auto;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
  position: relative;
}

.logobox1 {
  padding: 0;
  margin: 0;
  width: 70px;
}

.logobox1 img {
  padding: 0;
  margin: 0;
  width: 100%;
}

.loginbox h1 {
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 45px;
  font-weight: 300;
  color: #ffffff;
}

.loginbox h2 {
  padding: 0 0 30px 0;
  margin: 0;
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
}

.loginbox .form-control2 {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  border-bottom-color: #bcb5b5;
}

.loginbox .form-group .form-control {
  background: transparent;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
}

.Forgotlink a {
  color: #ffffff;
}

.glyphicon {
  color: #bcb5b5;
}

.headersection {
  padding: 8px 0;
  margin: 0 auto;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
}

.headersection .navbar-inverse {
  background: none;
  border: none;
  margin-bottom: 0;
}

.headersection .navbar-inverse .navbar-nav > .open > a,
.headersection .navbar-inverse .navbar-nav > .open > a:focus,
.headersection .navbar-inverse .navbar-nav > .open > a:hover {
  color: #000000;
  background: transparent;
}

.headersection .navbar ul li {
  position: relative;
}

.headersection .navbar ul li font {
  width: 25px;
  height: 25px;
  background: #58a308;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
  position: absolute;
  border-radius: 50%;
  top: 20px;
  right: 0;
  font-size: 14px;
}

.headersection .navbar ul li button {
  padding: 0.8571428571rem 0;
  color: #363636;
  width: 100%;
  margin: 0;
  display: block;
  font-size: 1.2857142857rem;
  line-height: 1.3333333333;
  white-space: break-spaces;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  text-align: left;
}

.headersection .navbar ul li button:hover {
  border-bottom: 1px solid #cccccc;
}

.logo_box {
  width: 70px;
  height: auto;
  padding: 0 0;
  margin: 0;
  display: inline-block;
}

.logo_box img {
  padding: 0px 0;
  margin: 0;
  width: 100%;
  height: auto;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #000000;
}

.headersection .navbar-nav.navbar-right img {
  width: 28px;
  height: 26px;
}

.headersection .navbar-nav.navbar-right li font {
  width: 25px;
  height: 25px;
  background: var(--amber-600);
  text-align: center;
  line-height: 25px;
  color: #ffffff;
  position: absolute;
  border-radius: 50%;
  top: 12px;
  right: 0px;
  font-size: 14px;
}

.headersection .navbar-nav > li > a {
  padding: 25px 15px;
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

.headersection .navbar-nav > li > a.active,
.headersection .navbar-nav > li > a:hover {
  color: #58a308;
}

.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  color: #58a308;
  background-color: transparent;
}

.headersection .dropdown ul.dropdown-menu {
  width: 22.8571428571rem !important;
  padding: 0.8571428571rem 3.5714285714rem 2.2857142857rem !important;
}

.headersection .dropdown ul.dropdown-menu li {
  padding: 0 0;
  margin: 0 0;
  width: 100%;
  text-align: left;
  background: none;
}

.headersection .dropdown ul.dropdown-menu li a {
  padding: 0.8571428571rem 0;
  color: #363636;
  width: 100%;
  margin: 0;
  display: block;
  font-size: 1.2857142857rem;
  line-height: 1.3333333333;
  white-space: break-spaces;
  background: none;
  border-bottom: 1px solid transparent;
}

.headersection .dropdown ul.dropdown-menu li a:hover {
  border-bottom: 1px solid #cccccc;
}

.headersection .dropdown ul.dropdown-menu li a span {
  padding: 0 5px;
}

.headersection .dropdown ul.dropdown-menu li.LogIn-box a {
  text-align: center;
  background: #224a05;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 7px;
  transition: all 0.5s ease 0s;
}

.headersection .dropdown ul.dropdown-menu li.LogIn-box a:hover {
  background: #f6a613;
  border: 2px solid #fff;
  color: #224a05;
  transition: all 0.5s ease 0s;
}

.footersection {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.footertop {
  padding: 25px 0;
  margin: 0 auto;
  width: 100%;
  background: #4d4d4f;
  position: relative;
}

.footertop h2 {
  padding: 0 0 10px;
  margin: 0;
  color: #fff;
  font-size: 17px;
  position: relative;
}

.footertop h2::before {
  width: 40px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  content: "";
  z-index: 9;
}

.footertop ul {
  padding: 10px 0;
  margin: 0;
}

.footertop ul li {
  list-style: none;
  line-height: 30px;
}

.footertop ul li,
.footertop ul li a {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 17px;
  transition: all 0.5s ease 0s;
}

.footertop ul li a {
  line-height: 24px;
}

.footertop ul li a:hover {
  color: #000;
}

.footertop ul.iconbox li {
  display: inline-block;
  padding: 0 5px 0 0;
}

.footertop .iconbox .fa {
  color: #fff;
  border: 1px solid #fff;
  margin: 0;
  padding: 0;
  font-size: 20px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  display: inline-block;
  transition: all 0.5s ease 0s;
  line-height: 34px;
}

.footertop .iconbox .fa:hover {
  transform: scale(1.2);
  transition: all 0.5s ease 0s;
}

.footerbottom {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  background: #262624;
}

.footerbottom p {
  padding: 10px 0;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}

.footerbottom p a {
  color: #fdc822;
}

.footerbottom p a:hover {
  color: #77a83d;
}

.bannersection {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.bannertext {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: 35%;
  text-align: center;
}

.bannertext.hombanner {
  padding: 40px;
  margin: 0 auto;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  max-width: 710px;
}

.bannertext h1 {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 38px;
  font-family: "Arvo", serif;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 1px 2px 3px #000000;
}

.bannertext h2 {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 68px;
  font-family: "Arvo", serif;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: 1px 2px 3px #000000;
}

.bannersection img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.bannersection .item {
  position: relative;
}

.bannersection .btn-default {
  background: #224a05;
  border: 2px solid #fff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 17px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 15px auto;
  font-weight: 600;
  transition: all 0.5s ease 0s;
}

.bannersection .btn-default:hover {
  background: #f6a613;
  border: 2px solid #fff;
  color: #224a05;
  transition: all 0.5s ease 0s;
}

.NEW-MENU-section {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 15%, #f6a613 15%, #f6a613 85%, #ffffff 85%, #ffffff 85%, #ffffff 85%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 15%, #f6a613 15%, #f6a613 85%, #ffffff 85%, #ffffff 85%, #ffffff 85%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 15%, #f6a613 15%, #f6a613 85%, #ffffff 85%, #ffffff 85%, #ffffff 85%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=0);
}

.ingbox1 {
  padding: 40px 0;
  margin: 0 auto;
  max-width: 490px;
}

.ingbox1 img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.NEW-MENU-section h1 {
  padding: 105px 0 10px;
  margin: 0;
  color: #ffffff;
  font-size: 34px;
  font-family: "Arvo", serif;
}

.NEW-MENU-section p {
  padding: 10px 0 20px;
  margin: 0;
  color: #ffffff;
}

.NEW-MENU-section .btn-default {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 17px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 auto 15px;
  font-weight: 600;
  transition: all 0.5s ease 0s;
}

.NEW-MENU-section .btn-default:hover {
  background: #224a05;
  border: 2px solid #fff;
  color: #ffffff;
  transition: all 0.5s ease 0s;
}

.MENU-OPTIONS-section {
  padding: 90px 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background: #ffffff;
}

.MENU-OPTIONS-section h2 {
  padding: 0 40px 60px;
  margin: 0;
  font-size: 40px;
  text-align: center;
  font-weight: 600;
}

.MENU-OPTIONS-section h3 {
  text-align: center;
  padding: 10px 20px;
  margin: 0 auto;
  font-size: 24px;
}

.MENU-OPTIONS-section p {
  text-align: center;
  padding: 10px 10px;
  margin: 0 auto;
  color: #414141;
  font-size: 15px;
}

.MENU-OPTIONS-section button {
  background: none;
  border: none;
  transition: all 0.2s ease-in-out 0s;
}

.MENU-OPTIONS-section button:hover {
  transform: scale(1.01);
  box-shadow: 1px 1px 10px rgba(134, 134, 134, 0.3);
  transition: all 0.2s ease-in-out 0s;
}

.MENU-OPTIONS-section ul {
  padding: 10px 10px;
  margin: 0 auto;
  text-align: left;
}

.MENU-OPTIONS-section ul li {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  font-size: 15px;
}

.MENU-OPTIONS-section .OPTIONS-box {
  padding: 0;
  margin: 0 auto;
  transition: all 0.5s ease 0s;
}

.MENU-OPTIONS-section .OPTIONS-box:hover {
  transform: scale(1.05);
  box-shadow: 0 0 5px #c6c3c3;
}

.MENU-OPTIONS-section a {
  color: #000000;
}

.MENU-OPTIONS-section .btn-default {
  background: transparent;
  border: 2px solid #82cb15;
  color: #82cb15;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 30px auto;
  font-weight: 600;
  transition: all 0.5s ease 0s;
}

.OPTIONS_img {
  padding: 0;
  margin: 0 auto;
  width: 300px;
  height: 300px;
}

.OPTIONS_img img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.CHOICE-section {
  padding: 90px 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background: #f5f5f5;
}

.CHOICE-section h2 {
  padding: 0 40px 60px;
  margin: 0;
  font-size: 40px;
  text-align: center;
  font-weight: 600;
}

.select_food,
.reviews-box {
  margin: 0 auto 15px;
  padding: 10px;
  width: 260px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 370px;
  /* box-shadow: 1px 1px 10px rgba(0,0,0,0.1); */
}

.select_food:hover,
.reviews-box:hover {
  transform: scale(1.03);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out 0s;
  /* cursor: pointer; */
}

.select_food_imgbox {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 190px;
  overflow: hidden;
  position: relative;
  background: #b6d89a;
  border-radius: 10px;
}

.select_food_imgbox:hover {
  cursor: pointer;
}

.select_food_title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.select_food_imgbox img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.dish-type_icon {
  position: absolute;
  height: 30px;
  width: 30px;
  background: #ffffff;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  text-align: center;
  display: inline-flex;
  padding: 4px 0 0 0;
}

.dish-type_icon img {
  height: 22px !important;
  width: 22px !important;
  padding: 0;
  margin: 0 auto;
  border: none !important;
}

.new_dish-type_icon {
  position: absolute;
  height: 32px;
  width: 76px;
  background-image: url("../images/img_1.png");
  background-position: top right;
  top: 0;
  left: 0;
  text-align: left;
  color: #ffffff;
  padding: 0 0 0 10px;
}

.select_food h3 {
  /* text-align: center; */
  /* padding: 10px 20px; */
  /* margin: 0 auto; */
  font-size: 18px;
  font-weight: 400;
  color: #4a4848;
}

/* .select_food p{
  padding: 0;
  margin: 0;
} */
.food_price {
  padding: 13px 5px 8px;
  margin: 0 auto;
  margin-top: 29px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

.food_price h3 {
  font-size: 40px;
  color: #f77f21;
  font-weight: 400;
}

.food_price ul {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.food_price ul li {
  padding: 0px 10px;
  margin: 0 auto;
  list-style: none;
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
}

.food_price ul li.old-price {
  color: #999999;
}

/* .food_price ul li.new-price { */
/* color: #686868; */
/* } */
.AddCartbox {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.AddCartbox button.link3 {
  font-size: 18px;
  text-transform: none;
  padding: 7px 15px;
  margin: 2px 0 0 0;
  height: auto;
}

.quantity-selector {
  margin: 2px 0 0 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 125px;
}

.quantity-selector .decrease {
  padding: 5px;
  padding-block: 7px;
  /* margin-right: 0; */
  border-radius: 5px 0 0 5px;
  /* background: #fff; */
}
.quantity-selector .decrease:hover {
  background-color: var(--gray-400);
  border: none;
  transition: background-color 0.25s ease-in-out;
}

.quantity-selector .increase {
  margin-left: 0;
  border-radius: 0 5px 5px 0;
  background: #fff;
}
.quantity-selector .increase:hover {
  background-color: var(--gray-400);
  border: none;
  transition: background-color 0.25s ease-in-out;
}

.quantity-selector .value-button {
  display: inline-block;
  margin-top: -3px;
  width: 30%;
  height: 100%;
  /* line-height: 17px; */
  text-align: center;
  vertical-align: middle;
  padding: 0 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.quantity-selector .value-button1 {
  text-align: center;
  border: none;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin: 0px;
  width: 40%;
  height: 39px;
}

.quantity {
  padding: 0;
  margin: 0;
}

.quantity .input-text {
  width: 100px;
  height: 45px;
  text-align: center;
  padding: 4px 15px;
  margin: 0;
  border: 1px solid #58a308;
  background: #ffffff;
}

.quantity-selector {
  margin: 2px 0 0 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 125px;
}

.quantity-selector .value-button {
  display: inline-block;
  margin-top: -5px;
  width: 40px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  vertical-align: middle;
  padding: 0 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.quantity-selector .value-button:hover {
  cursor: pointer;
}

.quantity-selector .decrease {
  margin-right: 0;
  border-radius: 5px 0 0 5px;
  background: #fff;
}

.quantity-selector .increase {
  margin-left: 0;
  border-radius: 0 5px 5px 0;
  background: #fff;
}

.quantity-selector .decrease:hover {
  color: #fff;
  background: #d9534f;
  border: 1px solid #d43f3a;
}

.quantity-selector .increase:hover {
  background: #77a83d;
  color: #ffffff;
  border: 1px solid #4cae4c;
}

.quantity-selector .value-button1 {
  text-align: center;
  border: none;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  margin: 0px;
  width: 43px;
  height: 39px;
}

.quantity-selector .value-button1::-webkit-inner-spin-button,
.quantity-selector .value-button1::-webkit-outer-spin-button {
  margin: 0;
}

.CHOICE-section .btn-default {
  background: #224a05;
  border: 2px solid #fff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 17px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 70px auto 0;
  font-weight: 600;
  transition: all 0.5s ease 0s;
}

.CHOICE-section .btn-default:hover {
  background: #f6a613;
  border: 2px solid #fff;
  color: #224a05;
  transition: all 0.5s ease 0s;
}

.WORKS-section {
  padding: 90px 0;
  margin: 0 auto;
  width: 100%;
  background: #ffffff;
}

.WORKS-section h2 {
  padding: 0 40px 60px;
  margin: 0;
  font-size: 40px;
  text-align: center;
  font-weight: 600;
}

.WORKS-section h3 {
  padding: 0 0 10px;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #ffcc00;
}

.WORKS-section p {
  padding: 0 0 10px;
  margin: 0;
}

.WORKS-box {
  padding: 15px;
  margin: 15px auto;
  transition: all 0.5s ease 0s;
}

.WORKS-box:hover {
  transition: all 0.5s ease 0s;
  box-shadow: 0 0 40px #e6e4e4;
}

.WORKS-box:nth-of-type(2n) {
  background: #ffffff;
}

.WORKS-box:nth-of-type(2n + 1) {
  background: #ffffff;
}

.WORKS-box:nth-of-type(2n + 1) .col-lg-9 {
  float: right;
}

.WORKS-box:nth-of-type(2n + 1) .col-lg-3 {
  float: right;
}

.WORKS-icon {
  padding: 0;
  margin: 20px auto 50px;
  width: 253px;
}

.WORKS-icon img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.ingbox2 {
  padding: 0 0;
  margin: 0 auto;
  max-width: 300px;
}

.ingbox2 img {
  padding: 0 0;
  margin: 0 auto;
  width: 100%;
}

.ABOUT-US-section {
  min-height: 250px;
  padding: 90px 0;
  margin: 0 auto;
  width: 100%;
  background-attachment: fixed;
  background-image: url("../images/bg4.jpg");
  background-position: bottom;
}

.ABOUT-US-section h2 {
  padding: 0 0 20px;
  margin: 0;
  font-size: 40px;
  font-weight: 600;
}

.ABOUT-US-section p {
  padding: 0 0 10px;
  margin: 0;
}

.ABOUT-US-section p a {
  color: #ffffff;
}

.ABOUT-US-section p a:hover {
  color: #000000;
}

.OUR-PRIORITY-section {
  padding: 90px 0;
  margin: 0 auto;
  width: 100%;
  background: #f5f5f5;
}

.OUR-PRIORITY-section h2 {
  padding: 0 0 20px;
  margin: 0;
  font-size: 40px;
  font-weight: 600;
}

.OUR-PRIORITY-section p {
  padding: 0 0 10px;
  margin: 0;
}

.Reviews-section {
  padding: 90px 0;
  margin: 0 auto;
  width: 100%;
  background-image: url("../images/bg3.jpg");
  background-position: bottom;
  background-repeat: repeat-x;
  background-color: #f3f5ed;
}

.Reviews-section h2 {
  padding: 0 40px 60px;
  margin: 0;
  font-size: 40px;
  text-align: center;
  font-weight: 600;
}

.reviews-box .imgbox2 {
  width: 60px;
  height: 60px;
  background: #ffffff;
  margin: 15px auto;
  padding: 0;
}

.reviews-box .imgbox2 img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 0;
}

.reviews-box .starbox {
  margin: 5px auto;
  padding: 0;
  text-align: center;
  color: #fbcf1e;
}

.reviews-box h4 {
  margin: 0 auto;
  padding: 0 0 5px;
  font-size: 20px;
  text-align: center;
}

.reviews-box p {
  margin: 0 auto;
  padding: 0 0 10px;
  font-size: 15px;
  text-align: center;
}

a.more {
  color: #85a335;
  font-weight: bold;
}

a.more:hover {
  color: #000000;
}

.map-section {
  padding: 0;
  margin: 0 auto;
  width: 50%;
  position: relative;
  background: #f8f9fb;
}

.add-box {
  padding: 50px;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  margin-top: 79px;
  margin-bottom: 79px;
}

/*.add-box p {
 padding: 0;
  margin: 0;
  color: #392500;
  font-size: 20px;
  font-weight: 600; 
}*/
.delivery_subtitle p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 30px;
  /* text-decoration: none solid rgb(139, 139, 139); */
}

/* .add-box p span {
padding: 0 0 25px;
  margin: 0;
  color: #ffffff;
  display: inline-block; 
}*/
.map-section .container-fluid {
  padding: 0;
  margin: 0 auto;
  position: absolute;
  top: 20px;
  left: 15px;
  right: 15px;
  z-index: 9;
  pointer-events: none;
}

.loginsection_bg {
  margin: 0 auto;
  background-attachment: fixed;
  background-image: url("../images/login-bg.jpg");
  background-position: center center;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 10px;
}

/* .loginsection_bg::before {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  content: "";
} */
.login-wrapper {
  max-width: 35.7142857143rem;
  padding: 0;
  margin: 15% auto;
  /* border: 1px solid #ffffff; */
  position: relative;
}

.login-wrapper h1 {
  padding: 0 10px;
  margin: 0.67em 0 !important;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.1578571429rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  font-family: "Arvo", serif;
}

.login-wrapper p {
  padding: 20px 0;
  margin: 0;
  text-align: center;
  color: #ffffff;
}

.login-wrapper input {
  width: 100% !important;
  font-size: 1.1428571429rem;
  line-height: 1.5;
  padding: 0.75rem 0.55rem !important;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #000;
  border-radius: 6px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
}

.login-wrapper .p-password {
  width: 100% !important;
  margin: 0 auto !important;
}

.login-wrapper .mb-5 {
  margin-bottom: 0 !important;
}

.login-wrapper .Signin {
  padding: 0;
  margin: 0;
  text-align: center;
}

.login-wrapper .Signin .p-button {
  background: var(--green-800);
  color: #fff;
  padding: 10px 60px;
  font-size: 17px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 400;
  margin: 10px 0;
  border-radius: 7px;
  transition: all 0.2s ease 0s;
}

.login-wrapper .Signin .p-button:hover {
  background: var(--green-900);
  color: #fff;
  transition: all 0.5s ease 0s;
}

.login-wrapper .p-button-label {
  font-weight: 400;
}

.login-wrapper .Forgotlink {
  padding: 0 0 15px;
  margin: 0;
  text-align: center;
}

.login-wrapper .Forgotlink a {
  padding: 0 0;
  margin: 0;
  color: #ffffff;
}

.ng-star-inserted .login-wrapper .pi-eye,
.ng-star-inserted .login-wrapper .pi-eye-slash {
  transform: scale(1.1);
  margin-right: 1rem !important;
  color: #000000 !important;
  right: 0;
  top: 15px;
  position: absolute;
}

.breadcrumb-list {
  padding: 110px 0 20px;
  /* margin: 0 auto; */
  /* width: 100%; */
  /* background: #f2f1f1; */
  /* text-align: left; */
  /* margin-left: 0; */
}

.breadcrumb-list h1 {
  color: #666666;
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
}

.breadcrumb-list .order_header ul {
  padding: 0;
  margin: 0;
  /* margin-left: 0 150px; */
}

.breadcrumb-list .order_header ul li {
  padding: 3px;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.breadcrumb-list .order_header ul li a {
  padding: 0;
  margin: 0;
  color: var(--gray-600);
  font-weight: 300;
}

.breadcrumb-list .order_header ul li a:hover,
.breadcrumb-list .order_header ul li a.active {
  color: #58a308;
}

.breadcrumb-section {
  padding: 110px 0 20px;
  margin: 0 auto;
  width: 100%;
  /* background: #f2f1f1; */
  text-align: center;
  /* margin-left: 0; */
}

.breadcrumb-section h1 {
  color: #666666;
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
}

.breadcrumb-section ul {
  padding: 0;
  margin: 0;
  /* margin-left: 0 150px; */
}

.breadcrumb-section ul li {
  padding: 3px;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.breadcrumb-section ul li a {
  padding: 0;
  margin: 0;
  color: var(--gray-600);
  font-weight: 300;
}

.breadcrumb-section ul li a:hover,
.breadcrumb-section ul li a.active {
  color: #58a308;
}

.search_box {
  padding: 15px;
  margin: 0 auto 15px;
  background: #ffffff;
}

.search-container {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.search-container .form-search {
  width: 80%;
  float: left;
  border: 1px solid #a2a2a2;
  border-right: none;
  padding: 0 5px;
  height: 45px;
  font-size: 18px;
  border-radius: 5px 0 0 5px;
}

.search-container .btn {
  width: 20%;
  float: left;
  border: 1px solid #77a83d;
  background: #77a83d;
  border-left: none;
  height: 45px;
  font-size: 18px;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
}

.search_box p {
  padding: 8px 0;
  margin: 0;
  color: #8a8a8a;
}

.search_box select {
  padding: 8px;
  margin: 0;
  color: #8a8a8a;
  background: none;
  border: none;
  float: right;
}

.foodsection {
  /* background-color: #fff; */
  padding-inline: 10px;
  margin: 0 auto 15px;
  border-radius: 4px;
  border-radius: 4px;
}

.foodsection_left {
  padding: 0;
  margin: 0 auto 15px;
  border-radius: 4px;
}

.foodsection_left-box {
  padding: 0;
  margin: 0 auto 15px;
}

.foodsection_left h1 {
  font-size: 24px;
  padding-inline: 20px;
  padding-top: 25px;
}

.foodsection_left h4 {
  padding: 4px 20px 0px;
  margin: 0 auto 0;
  /* border-left: 3px solid #77a83d; */
  color: var(--gray-700);
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
}

.foodsection_left ul {
  padding: 5px 0 20px;
  margin: 0;
}

.foodsection_left ul li {
  padding: 5px 15px;
  margin: 0;
  list-style: none;
  font-size: 17px;
  line-height: 22px;
  color: #484848;
}

.foodsection_left ul li input {
  margin-right: 5px;
}

/* .foodsection_left .p-checkbox .p-checkbox-box {
  border: 2px solid #5fad1f;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  position: relative;
  top: 4px;
} */
.foodsection_left .pi {
  font-size: 12px !important;
}

/* .foodsection_left .p-radiobutton .p-radiobutton-box {
  border: 2px solid #5fad1f;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  position: relative;
  top: 4px;
} */
/* .foodsection_left .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #14B8A6;
} */
.datebox {
  padding: 0;
  margin: 0px auto 10px;
}

.datebox2 {
  padding: 0px 23px;
  padding-bottom: 5px;
  margin: 0 auto;
}

.search-underline-div {
  padding-inline: 20px;
}

.search-underline-div div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.datebox2 .p-field-radiobutton,
.datebox2 .p-field-checkbox {
  padding: 0 0 6px 0;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
}

.datebox ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.datebox ul li {
  padding: 0;
  margin: 4px 2.5px;
  width: 22%;
  height: 72px;
  display: inline-block;
}

.datebox ul li .btn {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 72px;
  border: 1px solid #cecacb;
  color: #000000;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f2f1f1+0,cecacb+100 */
  background: #f2f1f1;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f2f1f1 0%, #cecacb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f2f1f1 0%, #cecacb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f2f1f1 0%, #cecacb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f1f1", endColorstr="#cecacb", GradientType=0);
  /* IE6-9 */
}

.datebox ul li .btn:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cecacb+0,f2f1f1+100 */
  background: #cecacb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #cecacb 0%, #f2f1f1 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #cecacb 0%, #f2f1f1 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #cecacb 0%, #f2f1f1 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cecacb", endColorstr="#f2f1f1", GradientType=0);
  /* IE6-9 */
}

.datebox ul li .btn font {
  font-size: 14px;
  display: block;
}

.datebox ul li .btn strong {
  font-size: 24px;
  display: block;
}

.foodsection_right {
  padding: 0;
  margin: 0 auto 15px;
}

.daybox {
  padding: 0;
  margin: 0 auto 15px;
  background: #ffffff;
}

.daybox h1 {
  padding: 3px 10px 10px;
  margin: 0 auto 0;
  border-left: 3px solid #77a83d;
  color: #666666;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
}

.default-color {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgb(255, 255, 255) 37%, rgba(242, 235, 22, 0.1575980734) 100%);
}

.daybox h1 span {
  font-size: 15px;
  color: #77a83d;
}

.daybox h2 {
  padding: 0;
  margin: 0 auto;
  font-size: 24px;
  text-transform: uppercase;
}

.link3 {
  background: #224a05;
  border: 2px solid #fff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 17px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 15px auto;
  font-weight: 600;
  transition: all 0.5s ease 0s;
}

.link3:hover {
  background: #f6a613;
  border: 2px solid #fff;
  color: #224a05;
  transition: all 0.5s ease 0s;
}

.foodsection_right .select_food_box {
  max-width: 287px;
  height: 450px;
  float: left;
  position: relative;
  min-height: 1px;
  padding: 0 10px;
  margin: 0 auto;
}

.foodsection_right .select_food_box .p-button {
  font-size: 16px;
}

.foodsection_right .select_food {
  /* width: 100%; */
  height: auto;
  width: 275px;
}

.food_menu_container {
  padding-inline: 0;
  margin: 0 auto 15px;
}

.food_menu_thumb {
  padding: 0;
  margin: 0 auto 15px;
  position: relative;
}

.food_menu_thumb img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid #77a83d; */
}

.vl {
  display: inline-block;
  height: 40rem;
  border-left: 1px solid rgb(225, 222, 222);
  position: absolute;
}

.food_menu_details {
  padding: 0;
  margin: 0 auto 15px;
}

.food_menu_details h2 {
  padding: 0;
  margin: 0 auto 15px;
  font-size: 30px;
}

.food_menu_details h2 span {
  padding: 0;
  margin: 0 auto;
}

.food_menu_details h2 span img {
  height: 22px;
  width: 22px;
  padding: 0;
  margin: 0 auto;
}

.food_menu_details .food_price {
  padding: 0px 0 15px 0;
  margin: 0 auto;
}

.food_menu_details p {
  padding-bottom: 15px;
}

.food_menu_details .food_price ul {
  text-align: left;
}

.food_menu_details .food_price ul li {
  padding: 0 10px 0 0;
}

.food-topic {
  padding: 0;
  margin: 0;
}

.food-topic ul {
  padding: 0;
  margin: 0 0 15px 0;
  /* border-bottom: 1px solid #ccc; */
}

.food-topic ul li {
  padding: 0px;
  margin: 0 30px 5px 0;
  list-style: none;
  /* border: 1px solid #58a308; */
  background: #ffffff;
  width: 184px;
  display: inline-block;
  /* border-radius: 5px; */
}

.food-topic ul li .pull-left {
  color: #363434;
  font-size: 18px;
  font-weight: 400;
}

.food-topic ul li .pull-right {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.related-box {
  padding: 0;
  margin: 0 auto 15px;
}

.related-box h4 {
  padding: 0;
  margin: 0 auto 20px;
  font-size: 25px;
  font-weight: bold;
}

.cart_box {
  padding: 0;
  margin: 0px auto 15px;
  width: 100%;
}

.table_box {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  background: #ffffff;
}

.cart_box .btn-danger {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 27px;
  line-height: 27px;
}

.table_box .table {
  margin-bottom: 0;
}

.totalbox {
  padding: 0;
  margin: 0 auto 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d2d6de;
}

.totalbox > div {
  padding: 10px 15px;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid #d2d6de;
}

.your-order-box {
  padding: 0;
  margin: 0 auto 15px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d2d6de;
  border-radius: 10px;
}

.your-order-box > div {
  padding: 10px 19px;
  margin: 0 auto;
  width: 100%;
}

.panel-heading .accordion-toggle:after {
  font-family: "Glyphicons Halflings";
  content: "\e114";
  float: right;
  color: grey;
}

.panel-heading .accordion-toggle.collapsed:after {
  content: "\e080";
}

a.accordion-toggle {
  display: block;
}

.checkout-accordion {
  padding: 0;
  margin: 0;
}

.checkout-accordion .panel-title {
  font-size: 18px;
  color: #000000;
  line-height: 30px;
}

.checkout-accordion .panel-default > .panel-heading {
  color: #000000;
  background-color: #fbfdfa;
  border: none;
}

.checkout-accordion .radio {
  margin: 0 0 15px 0;
}

.checkout-accordion .radio input {
  margin: 10px 0 0 -20px;
}

.checkout-accordion .checkbox input {
  margin: 10px 0 0 -20px;
}

.payment_box {
  padding: 0;
  margin: 0 auto 15px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #d2d6de;
}

.payment_box > div {
  padding: 10px 15px;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid #d2d6de;
}

.payment_box .radio {
  margin: 0;
}

.payment_box .radio input {
  margin: 10px 0 0 -20px;
}

.form-control2 {
  display: block;
  width: 100%;
  min-height: 37px;
  height: auto;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d2d6de;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.Profile_box {
  padding: 0;
  margin: 0 auto 15px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #d2d6de;
}

.Profile_box > div {
  padding: 10px 15px;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid #d2d6de;
}

.Profile-menu {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.Profile-menu ul {
  padding: 0;
  margin: 0 auto;
}

.Profile-menu ul li {
  padding: 10px;
  margin: 0 auto;
  list-style: none;
  display: inline-block;
}

.Profile-menu ul li a {
  padding: 0;
  margin: 0 auto;
  color: #747470;
}

.Profile-menu ul li a:hover,
.Profile-menu ul li a.active {
  color: #58a308;
}

.Profile_box h2 {
  padding: 3px 10px 10px;
  margin: 0 auto 0;
  border-left: 3px solid #77a83d;
  color: #666666;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
}

.Order-History_box {
  padding: 10px;
  margin: 15px auto;
  border: 1px solid #d2d6de;
}

.sc-bQduHL {
  padding: 0 0 5px 0;
  margin: 0 auto;
  /* border-bottom: 1px solid #ccc9c9; */
}

.sc-bQduHL2 h4 {
  padding: 0 10px 10px 10px;
  margin: 0;
}

.sc-bQduHL1 {
  padding: 0;
  margin: 0;
  max-width: 70px;
  height: 70px;
  float: left;
}

.sc-bQduHL1 img {
  padding: 0;
  margin: 0;
  width: 100%;
}

.sc-bQduHL2 {
  padding: 0;
  margin: 0;
  max-width: 500px;
  float: left;
}

.sc-feWbDf {
  padding: 5px 0;
  margin: 0;
}

.ZSnmX {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.2rem;
  margin: 0px;
  font-weight: 500;
  color: rgb(105, 105, 105);
  line-height: 20px;
  padding: 0 15px;
}

.eYBRSw {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  padding: 0 15px;
}

.Profile_button button {
  background: none;
  font-size: 20px;
}

.Profile_button button .fa-pencil {
  color: #58a308;
}

.Profile_button button .fa-trash-o {
  color: #ff0000;
}

.menu-option-header {
  color: rgb(46, 44, 44);
  font-size: 30px;
}

.select_food_field {
  padding-top: 10px;
  font-size: 14px;
  /* font-weight: 700; */
  color: #525252;
  letter-spacing: 0.3px;
  margin-block: -3px;
}

.select_food_field_value {
  letter-spacing: 0.3px;
  font-size: 14px;
  margin-block: -8px;
  font-weight: 400;
  color: #6f6f6f;
}

.max_price_field {
  /* margin: 0 0 10px; */
  margin-top: 4px;
  margin-bottom: -2px;
  color: #4b4642;
}

.order-summary-header {
  margin-top: 10px;
}

.order-summary-header > strong {
  font-weight: 500;
  font-size: 20px;
}

.order-summary-block > img {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.order-summary-item-count {
  color: #646464;
  font-size: 15px;
}

.order-summary-border-box {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.order-summary-price-section {
  /* border-top: 1px solid rgba(0,0,0,0.1); */
  /* border-top: 1px solid rgba(0,0,0,0.1); */
  padding-block: 1px !important;
}

.order-total-field {
  color: #545454;
  font-size: 15px;
}

.checkout-box {
  margin-bottom: 20px;
}

.checkout-button-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.delivery-method-box {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 175px;
  height: 63px;
}

.address-block__selected {
  border-color: #5fad1f !important;
  box-shadow: 0 0 0 0.2rem #a2d17c !important;
  background: #f7fff1 !important;
}

.address-block__selected .checkout-address-field {
  color: #22400a;
}

.address-block__selected .checkout-address-text {
  color: #274b0b;
}

.checkout-address-block {
  box-shadow: 1px solid var(--surface-200);
}

.address-block__selected {
  border-color: #5fad1f !important;
  box-shadow: 0 0 0 0.2rem #a2d17c !important;
}

.checkout-address-block:hover {
  border-color: #5fad1f;
  box-shadow: 0 0 0 0.2rem #a2d17c;
  cursor: pointer;
  transition: all 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.checkout-address-text {
  font-size: 15px;
  color: #545454;
}

.checkout-address-field {
  font-size: 14px;
  font-weight: 500;
}

.readonly-field {
  background: #eee !important;
}

.or-text:after,
.or-text:before {
  content: "     ";
  text-decoration: line-through;
}

.checkout-item-list-details {
  margin-top: 5px;
}

.checkout-item-list-details p {
  margin: 0;
}

/* .cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.cart-item_header {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

p {
  margin: 0px 0 0px;
}

.cart-item_header h4 {
  margin-block: 3px;
  margin-bottom: 5px;
}

.cart-item_header div {
  margin-left: 15px;
}

.cart-item_header h5 {
  margin-block: 3px;
}

.cart-item_header h4 {
  margin-block: 3px;
}

.cart-item_header__img {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.cart-item_header p {
  margin-block: 0;
  line-height: 22px;
  font-size: 14px;
  color: var(--secondary-text);
}

.cart-item_header p span {
  color: #222;
}

.cart-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 1rem;
}

/************* PrimeNg CSS Overrides ************************************/
.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
}

.card:last-child {
  margin-bottom: 0;
}

.shadow-card {
  background: var(--surface-card);
  border: none;
  box-shadow: 1px 2px 24px var(--gray-200) !important;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
  color: #4b4642;
  font-weight: 400;
}

.p-component {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.5rem;
  font-weight: normal;
}

.p-slider.p-slider-horizontal {
  height: 1.286rem;
}

.p-slider .p-slider-handle {
  height: 2.143rem;
  width: 2.143rem;
  background: #ffffff;
  border: 2px solid #14b8a6;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider-horizontal .p-slider-handle {
  top: 0 !important;
}

.mx-10 {
  margin-left: 8rem;
  margin-right: 10.5rem;
}

.p-button {
  color: #ffffff;
  background: #5fad1f;
  border: 1px solid #5fad1f;
  padding: 0.35rem 1.25rem;
  font-size: 1.7rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-button-label {
  font-weight: 400;
}

.p-button:enabled:hover {
  background: #4e9415;
  color: #fff;
  border-color: #4e9415;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #61d800, 0 1px 1px 0 #fefefe;
}

.p-steps .p-steps-item .p-steps-title {
  font-weight: 700;
  color: #495057;
  white-space: pre-wrap;
  text-align: center;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 6px;
  background: transparent;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 38%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #eeffe9;
  color: var(--primary-color-alt);
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  /* color: #495057; */
  border: 1px solid #e9ecef;
  /* background: #ffffff; */
  min-width: 3rem;
  height: 3rem;
  line-height: 2rem;
  font-size: 1.5rem;
  z-index: 1;
  font-weight: 500;
  border-radius: 50%;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.5rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.55rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;
  width: 100% !important;
}

.p-inputtext:enabled:hover {
  border-color: #5fad1f;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a2d17c;
  border-color: #c0ff8d;
  outline: none;
}

.p-field label {
  font-size: 14px;
  color: #414141;
  font-weight: 500;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-shadow);
  background: var(--primary-color-alt);
}

label {
  margin-bottom: 0px;
}

.secondary-btn .p-button.p-button-outlined {
  background-color: rgba(95, 173, 31, 0.0784313725);
  color: #387a03;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color-alt);
}

.p-button.p-button-outlined:enabled:hover {
  background: rgba(20, 184, 166, 0.04);
  color: var(--primary-color-alt);
  border: 1px solid;
}

.p-button.p-button-link:enabled:hover {
  color: var(--primary-link-alt);
}

.p-button.p-button-link {
  color: var(--primary-link);
}

.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.p-inline-message {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  vertical-align: top;
  width: 100%;
}

.p-inline-message.p-inline-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0px;
  color: #cc8925;
  width: 100%;
}

.p-inline-message.p-inline-message-info {
  /* background: #fff2e2; */
  /* border: solid #cc8925; */
  font-weight: 500;
  border-width: 0px;
  /* color: #cc8925; */
  width: 100%;
}

.p-inline-message.p-inline-message-error {
  background: #ffe7e6;
  border: 2px solid #e79090;
  /* border-width: 0px; */
  color: #ff5757;
}

.p-inline-message .p-inline-message-text {
  font-size: 1.5rem;
  font-weight: 500;
}

.p-inline-message .p-inline-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.pi {
  font-size: 1.5rem !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-color-alt);
}

.p-message {
  /* font-weight: 500; */
  font-size: 1.5rem;
  /* width: 1000px; */
}

.p-message.p-message-success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0 0 0 6px;
  color: #177c5b;
  border: 2px solid #2adca2;
}

.p-tooltip {
  width: 300px !important;
}

.table-bordered {
  border-collapse: collapse;
}

.table-bordered > tbody > tr:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.MyCart-table .table-bordered > tbody > tr > td,
.MyCart-table .table-bordered > tbody > tr > th,
.MyCart-table .table-bordered > tfoot > tr > td,
.MyCart-table .table-bordered > tfoot > tr > th,
.MyCart-table .table-bordered > thead > tr > td,
.MyCart-table .table-bordered > thead > tr > th {
  border: 1px solid transparent;
}

.inner-page-section {
  font-size: 18px;
  line-height: 1.42857143;
  color: #333;
}

.inner-page-section h1 {
  padding: 0;
  margin: 60px 0 60px 0;
  font-size: 36px;
  position: relative;
}

.inner-page-section h2 {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 25px;
  position: relative;
}

.inner-page-section p {
  padding: 0;
  margin: 0 0 15px 0;
  text-align: justify;
}

.inner-page-section p span {
  padding: 0;
  margin: 0 0 0;
  color: #65a30d;
}

.inner-page-section .ingbox1 {
  padding: 0 0;
  margin: 0 auto;
  max-width: 490px;
}

.p-image-preview-container > img {
  cursor: pointer;
  object-fit: cover;
}

.MyProfile-section .address-del {
  width: 267px;
  height: 340px;
  float: left;
  position: relative;
  min-height: 1px;
  padding: 13px;
  margin: 0 15px 10px 0;
  background: #ffffff;
  color: #495057;
  cursor: pointer;
}

.MyProfile-section .address-del:hover {
  -webkit-box-shadow: 0px 10px 24px -15px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 0px 10px 24px -15px rgba(0, 0, 0, 0.66);
  box-shadow: 0px 10px 24px -15px rgba(0, 0, 0, 0.66);
}

.MyProfile-section .address-del .p-card {
  box-shadow: none;
}

.address-details p {
  font-size: 15px;
  text-align: left;
  font-weight: 400;
}

.max-z-index {
  z-index: 2147483647;
}

.Reviews-section-box {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.Reviews-section-box .reviews-box {
  display: inline-grid;
  margin: 5px;
  min-width: 300px;
}

.Reviews-section-box .reviews-box p {
  margin: 0 auto;
  padding: 0 0 10px;
  font-size: 15px;
  text-align: center;
}

.view-button {
  background: #224a05;
  border: 2px solid #fff;
  color: #fff;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 17px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 0 auto 0;
  font-weight: 600;
  transition: all 0.5s ease 0s;
}

.view-button:hover {
  background: #f6a613;
  border: 2px solid #fff;
  color: #224a05;
  transition: all 0.5s ease 0s;
}

.view-button a {
  color: #fff;
}

.view-button:hover {
  background: #f6a613;
  border: 2px solid #fff;
  color: #224a05;
  transition: all 0.5s ease 0s;
}

.Gallery-section {
  padding: 0;
  margin: 5px;
  text-align: center;
}

.Gallery-section .gallery-item {
  padding: 0;
  margin: 5px;
  width: 260px;
  display: inline-block;
  cursor: pointer;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
}

.lg-components {
  top: 90%;
}

.Addbag.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
  color: #2e2e2e;
}

.Addbag.tooltip .bottom {
  min-width: 300px;
  top: 40px;
  left: 130px;
  transform: translate(-50%, 0);
  padding: 10px 20px;
  color: #ffffff;
  background: #495057;
  font-weight: normal;
  font-size: 15px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
}

.Addbag.tooltip:hover .bottom {
  display: block;
}

.Addbag.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.Addbag.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.order-received-box {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.order-received-box h3 {
  font-size: 30px;
  margin: 0 auto;
  text-align: center;
}

.order-check {
  margin: 15px auto;
  padding: 0;
  background: #079332;
  color: #ffffff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 45px;
  font-size: 30px;
}

.mobile-section {
  display: none;
  position: relative;
}

.mobile-section .navbar-toggle {
  float: left;
}

.mobile-section .navbar-brand {
  float: none;
}

.mobile-section .m-logo_box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  width: 50px;
  margin: 0 auto;
}

.mobile-section .m-cart {
  right: 25px;
  top: 8px;
  position: absolute;
  z-index: 9;
}

.mobile-section .logo_box {
  width: 50px;
}

.mobile-section .navbar-inverse {
  z-index: 9;
}

.mobile-section .m-cart font.ng-star-inserted {
  width: 25px;
  height: 25px;
  background: #58a308;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: -10px;
  font-size: 14px;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border: none;
}

.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}

.headersection .mobile-section .navbar-nav > li > a {
  font-weight: 400;
  color: #626262;
}

.headersection .mobile-section .navbar-inverse .navbar-nav > li > a:focus,
.headersection .mobile-section .navbar-inverse .navbar-nav > li > a:hover {
  color: #58a308;
  background-color: transparent;
}

.p-radiobutton .p-radiobutton-box {
  border: 1px solid #ced4da;
  background: transparent;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da;
  background: transparent;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary-color-alt);
  background: var(--primary-color-alt);
}

.p-radiobutton-label {
  margin-left: 0.5rem;
  color: var(--gray-600);
  font-weight: 400;
}

.checkbox-block__selected {
  border-color: #5fad1f !important;
  box-shadow: 0 0 0 0.2rem #a2d17c !important;
}

.checkbox-block__error {
  border-color: rgba(255, 61, 50, 0.2705882353) !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 61, 50, 0.768627451) !important;
  background-color: rgba(255, 208, 206, 0.1803921569) !important;
}

.checkbox-block__error .checkout-address-field {
  color: var(--red-900) !important;
}

.p-selectbutton {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem !important;
}

.p-buttonset .p-button {
  margin: 0.5rem !important;
  border: 1px solid var(--gray-300) !important;
  border-radius: 6px !important;
  color: var(--gray-500);
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--primary-color-alt);
  border-color: #0d9488;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color-alt);
  border-color: none;
  color: #ffffff;
}

.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  width: 100% !important;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.anchor-link {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray-500);
}
.anchor-link:hover {
  text-decoration: underline;
  color: var(--gray-600);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.checkbox-block__error .checkout-address-field {
  color: var(--red-900) !important;
}

.main .pmsg {
  position: absolute;
  left: 0;
  right: 0;
  top: 92px;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  padding-left: 1.5rem !important;
}

.p-dropdown-label {
  padding-left: 1.5rem !important;
}

.inner-page-section {
  padding: 0;
  margin: 50px auto;
  width: 100%;
}

.innerbanner .bannertext h1 {
  padding: 70px 0 0 0;
  margin: 0 auto;
}

.inline-block.header_name.relative,
.item-name,
.price,
.select_food h3 {
  font-family: "Roboto", sans-serif;
}

.inner-page-section h6 {
  padding: 25px 0 10px;
  margin: 0 0 0 0;
  font-size: 25px;
  position: relative;
}

.inner-page-section h3 {
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 20px;
  line-height: 1.2;
  position: relative;
  font-weight: 900;
  color: #65a30d;
}

.Values_box {
  padding: 0 0;
  margin: 0 auto;
  width: 100%;
  max-width: 380px;
  text-align: left;
}

.ingbox3 {
  padding: 0 0;
  margin: 0 auto 20px;
  width: 100%;
}

.ingbox3 img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}

.food-Values {
  min-height: 250px;
  padding: 90px 0;
  margin: 100px auto 100px;
  width: 100%;
  background-attachment: fixed;
  background-image: url("../images/bg5.jpg");
  background-position: bottom;
}

ul.text-dim {
  padding: 0;
  margin: 0 0 2rem 2rem;
}

ul.text-dim li {
  padding: 4px 0;
  margin: 0 0 0 0;
  list-style: none;
}

ul.text-dim li::before {
  font-family: FontAwesome;
  padding: 0 8px 0 0;
  margin: 0;
  display: inline-block;
  content: "\f111";
  font-size: 15px;
  line-height: 22px;
  color: #65a30d;
}

.Testimonial-box {
  padding: 0;
  margin: 0 auto 25px;
  width: 100%;
}

.ingbox5 {
  position: relative;
  margin: 0;
  padding-left: 20px;
  padding-top: 25px;
  width: 100%;
}

.ingbox5 img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  border-radius: 10px;
}

.ingbox5::after {
  content: "";
  display: block;
  width: 82px;
  height: 82px;
  background-color: #65a30d;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.Testimonial-box .starbox {
  margin: 5px auto;
  padding: 0;
  text-align: left;
  color: #65a30d;
}

.texbox5 {
  padding: 25px;
  margin: 0 auto;
}

.texbox5 p {
  padding: 0 0 10px 0;
  margin: 0 auto;
  text-align: left;
  font-size: 23px;
  line-height: 38px;
}

.texbox5 h4 {
  padding: 30px 0 0 0;
  margin: 0;
  text-align: left;
  font-size: 22px;
}

.texbox5 a.more {
  font-size: 17px;
}

.works_box {
  position: relative;
}

.works_box .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #999;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.works_box .row {
  display: flex;
}

.works_box .row-1 {
  justify-content: flex-start;
}

.works_box .row-2 {
  justify-content: flex-end;
}

.works_box .row section {
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
  border: 1px solid #999;
}

.works_box .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #999;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}

.works_box .row-1 section::before {
  right: -7px;
}

.works_box .row-2 section::before {
  left: -7px;
}

.works_box .row section .icon, .center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ff7979;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.works_box .center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}

.works_box .center-line .scroll-icon img {
  margin: 0;
  padding: 0;
  width: 100%;
}

.works_box .row section .icon.scroll-icon2 {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
}

.works_box .row section .icon.scroll-icon2 img {
  margin: 0;
  padding: 0;
  width: 100%;
}

.works_box .row-1 section .icon {
  top: 15px;
  right: -60px;
}

.works_box .row-2 section .icon {
  top: 15px;
  left: -60px;
}

.works_box .row section .details, .row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.works_box .row section .details .title {
  font-size: 22px;
  font-weight: 600;
}

.works_box .row section ul {
  margin: 0;
  padding: 0 15px;
}

.works_box .row section ul li {
  margin: 0;
  padding: 0;
}

.works_box .row section .bottom a {
  text-decoration: none;
  background: #ff7979;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
}

.works_box .row section .bottom a:hover {
  transform: scale(0.97);
}

@media (max-width: 790px) {
  .works_box .center-line {
    left: 40px;
  }
  .works_box .row {
    margin: 30px 0 3px 60px;
  }
  .works_box .row section {
    width: 100%;
  }
  .works_box .row-1 section::before {
    left: -7px;
  }
  .works_box .row-1 section .icon {
    left: -60px;
  }
}
@media (max-width: 440px) {
  .works_box .center-line, .row section::before, .row section .icon {
    display: none;
  }
  .works_box .row {
    margin: 10px 0;
  }
}
.works_box .WORKS-box {
  padding: 0;
  margin: 0 auto;
  transition: all 0.5s ease 0s;
}

.works_box .WORKS-box:hover {
  transition: all 0.5s ease 0s;
  box-shadow: none;
}

.works_box .ingbox2 {
  padding: 0 0;
  margin: 0 15px 15px 0;
  max-width: 115px;
  float: left;
}

.Members-section {
  padding: 0;
  margin: 0 auto;
}

.Members-section ul {
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.Members-section ul li {
  padding: 0;
  margin: 10px;
  list-style: none;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s ease 0s;
}

.Members-section .Values_box {
  padding: 0 0;
  margin: 0 auto;
  width: 100%;
  max-width: 280px;
  text-align: left;
}

.Members-section .Values_box h6 {
  padding: 5px 0 10px;
  margin: 0 0 0 0;
  font-size: 25px;
  position: relative;
  text-align: left;
}

.Members-section .Values_box p {
  text-align: left;
}

.btn-2 {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.btn-2::before {
  content: "";
  position: absolute;
  height: 70px;
  width: 100%;
  background: #fff;
  left: 0;
  top: -70px;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn-2:hover:before {
  top: 77%;
}

.Staff-Page-section h1 {
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 24px;
}

.Staff-Page-section h2 {
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 19px;
}

.Staff-Page-section .modal-dialog {
  width: 60%;
  margin: 30px auto;
}

.overflow-section {
  overflow-x: scroll;
  height: 500px;
  padding: 0 15px;
}

.overflow-section p {
  padding: 0 0 10px 0;
  margin: 0;
}