@media only screen and (min-width:320px) and (max-width:480px) {
  .headersection .navbar-nav.navbar-right li font {
    top: 8px;
    left: 28px;
  }

  .navbar-inverse .navbar-toggle:focus,
  .navbar-inverse .navbar-toggle:hover {
    background: transparent;
  }

  .navbar-header {
    padding-bottom: 5px;
  }

  .headersection {
    background: #fff;
    position: relative;
  }

  .headersection .navbar-nav>li>a {
    padding: 10px;
  }

  .bannertext h1 {
    font-size: 20px;
  }

  .bannertext h2 {
    font-size: 30px;
  }
  .bannertext {
    top: 15%;
  }
  .bannertext.hombanner  {
    top: 0;
    padding: 0;
  }

  .NEW-MENU-section h1 {
    padding: 10px 0;
    font-size: 30px;
  }

  .NEW-MENU-section {
    background: #f6a613;
  }

  .WORKS-section h2 {
    font-size: 35px;
  }

  .bannersection .btn-default {
    padding: 0 15px;
    font-size: 15px;
    line-height: 25px;
  }

  .WORKS-box:nth-of-type(2n+1) .col-lg-3 {
    float: none;
  }

  .breadcrumb-section {
    padding-top: 20px;
  }

  .foodsection_right .select_food_box {
    max-width: 100%;
    height: auto;
    float: none;
  }

  .map-section .container-fluid {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 85%;
  }

  .MyProfile-section .address-del {
    width: 100%;
    height: auto;
    float: none;
  }

  .ABOUT-US-section {
    background-image: none;
    background-color: #e2d8cf;
  }

  .desktop-section {
    display: none;
  }

  .mobile-section {
    display: block;
  }

  .mobile-section-box .card.checkout-address-block {
    width: 100%;

    padding: 10px;
    margin: 10px 0 !important;
  }

  .mobile-section-box .surface-50 {
    background-color: transparent !important;
  }

  .mobile-section-box .surface-50.p-5.rounded-md {
    padding: 0 !important;
  }

  .main .pmsg {
    top: 71px;
  }
  .innerbanner .bannertext h1 {
    padding: 30px 0 0;
  }

  .Reviews-section-box {
    width: auto;
    text-align: center;
    margin: 0 auto;
    height: auto;
  }
  .Reviews-section-box .testimonial-content {
    padding: 15px;
    text-align: left;
  }

  .Reviews-section-box .testimonial-thumb {
    position: relative;
    text-align: center;
    left: 0;
    top: 15px;
    transform: translateY(0%);
    right: 0;
    margin: 0 auto;
  }


  .Reviews-section-box .testimonial-content .testimonial-content-icon {
    margin: 30px auto 0;
  }
  .Staff-Page-section .modal-dialog {
    width: 95%;
  }

}

@media only screen and (min-width:481px) and (max-width:768px) {
  .MyProfile-section .address-del {
    width: 100%;
    height: auto;
    float: none;
  }

  .headersection .navbar-nav>li>a {
    padding: 10px;
  }

  .desktop-section {
    display: block;
  }

  .mobile-section {
    display: none;
  }
  .Staff-Page-section .modal-dialog {
    width: 95%;
  }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
  .WORKS-box:nth-of-type(2n+1) .col-lg-3 {
    float: none;
  }

  .foodsection_right .select_food_box {
    max-width: 50%;
  }

  .OPTIONS_img {
    width: 170px;
    height: 170px;
  }

  .MENU-OPTIONS-section h3 {
    padding: 5px 20px 0;
  }

  .MyProfile-section .address-del {
    width: 100%;
    height: auto;
    float: none;
  }

  .ABOUT-US-section {
    background-image: none;
    background-color: #e2d8cf;
  }

  .MENU-OPTIONS-section p {
    padding: 10px 25px;
  }

  .headersection {
    background: #ffffff;
    position: relative;
  }

  .NEW-MENU-section .btn-default {
    margin: 15px auto 150px;
  }

  .bannertext {
    top: 20%;
  }


  .mobile-section {
    display: none;
  }
  .Staff-Page-section .modal-dialog {
    width: 95%;
  }

}

@media only screen and (min-width:1025px) and (max-width:1200px) {
  .WORKS-box:nth-of-type(2n+1) .col-lg-3 {
    float: none;
  }

  .select_food,
  .reviews-box {
    width: 225px;
  }

  .foodsection_right .select_food_box {
    max-width: 50%;
  }

  .MENU-OPTIONS-section h3 {
    padding: 10px 40px 0;
  }

  .MyProfile-section .address-del {
    width: 100%;
    height: auto;
    float: none;
  }

  .NEW-MENU-section .btn-default {
    margin: 15px auto 150px;
  }

  .mobile-section {
    display: none;
  }
  .Staff-Page-section .modal-dialog {
    width: 95%;
  }
}

@media only screen and (min-width:1201px) {}